<template>
    <PPHeader :title="editMode ? `Order details #${orderForm.number ?? ''}` : 'Nieuwe order'" />

    <form @submit.prevent="submit" class="my-5">
        <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
            <PPSelect
                name="channel"
                :options="stateOptions"
                label="Status"
                v-model="orderForm.state"
                :errors="v$.orderForm.state.$errors.length"
            />
        </div>

        <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
            <PPComboSelect
                v-model="selectedCustomer"
                url="/admin/customers?search[name]=%query%"
                name="customer"
                :formatResponseData="formatCustomers"
                @update:modelValue="updateCustomer"
                label="Klant"
                :nullable="true"
                :errors="v$.orderForm.customer.id.$errors.length"
            />
        </div>

        <div class="mt-3 grid grid-cols-1 sm:grid-cols-2" v-if="!orderForm.customer.id">
            <PPSelect
                name="channel"
                :options="channelOptions"
                label="Kanaal"
                v-model="orderForm.channel"
                :errors="v$.orderForm.channel.$errors.length"
            />
        </div>

        <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
            <PPInput
                class="col-span-1"
                v-model="orderForm.customer.name"
                name="name"
                label="Naam"
                :errors="v$.orderForm.customer.name.$errors.length"
            />
        </div>

        <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
            <PPInput
                class="col-span-1"
                v-model="orderForm.customer.email"
                name="email"
                label="E-mailadres"
                :errors="v$.orderForm.customer.email.$errors.length"
            />
        </div>

        <div class="mt-3 grid grid-cols-1 sm:grid-cols-6 gap-y-3 sm:gap-x-3">
            <PPInput
                class="col-span-2"
                v-model="orderForm.invoiceAddress.street"
                name="street"
                label="Straatnaam"
                :errors="v$.orderForm.invoiceAddress.street.$errors.length"
            />

            <PPInput
                v-model="orderForm.invoiceAddress.houseNumber"
                name="houseNumber"
                label="Huisnummer"
                :errors="v$.orderForm.invoiceAddress.houseNumber.$errors.length"
            />
        </div>

        <div class="mt-3 grid grid-cols-1 sm:grid-cols-6 gap-y-3 sm:gap-x-3">
            <PPInput
                class="col-span-2"
                v-model="orderForm.invoiceAddress.city"
                name="city"
                label="Plaats"
                :errors="v$.orderForm.invoiceAddress.city.$errors.length"
            />

            <PPInput
                v-model="orderForm.invoiceAddress.zipCode"
                name="zipCode"
                label="Postcode"
                :errors="v$.orderForm.invoiceAddress.zipCode.$errors.length"
            />
        </div>

        <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
            <PPSelect
                name="country"
                :options="countryOptions"
                label="Land"
                v-model="orderForm.invoiceAddress.country"
                :errors="v$.orderForm.invoiceAddress.country.$errors.length"
            />
        </div>

        <hr class="my-5" />

        <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
            <PPComboSelect
                name="pickup_location"
                v-model="selectedPickupLocation"
                :options="pickupLocationOptions"
                label="Pickup locatie"
                @update:modelValue="($event) => updatePickupLocation($event)"
                :errors="v$.orderForm.pickupLocation.$errors.length"
            />
        </div>

        <div
            class="mt-3 grid grid-cols-1 sm:grid-cols-2"
            v-if="
                selectedPickupLocation.supportsTimeslots && selectedPickupLocation.timeslots?.length
            "
        >
            <PPSelect
                name="timeslot"
                :options="timeslotOptions"
                v-model="orderForm.pickupTimeslot"
                label="Pickup timeslot"
                :errors="v$.orderForm.pickupTimeslot.$errors.length"
            />
        </div>

        <hr class="my-5" />

        <div class="flex gap-3">
            <PPButton @click="addProduct">Nieuw product +</PPButton>
        </div>

        <div class="border-t border-gray-100 mt-3" v-if="orderForm.lines.length">
            <dl class="divide-y divide-gray-100">
                <PPOrderLine
                    v-for="(line, index) in orderForm.lines"
                    :key="line.productCode"
                    :orderLine="line"
                    :taxCategoryOptions="taxCategoryOptions"
                    @update:orderLine="(data) => updateOrderLine(index, data)"
                    @removeProduct="removeProduct(index)"
                />
            </dl>
        </div>

        <div class="mt-3 grid grid-cols-1 sm:grid-cols-2">
            <PPInput
                name="discountPercent"
                v-model="orderForm.discountPercent"
                type='number'
                label="Totale korting (%)"
                :errors="v$.orderForm.discountPercent.$errors.length"
            />
        </div>

        <hr class="my-5" />

        <PPAlert class="mb-3" :error="error" />

        <PPButton type="submit">Opslaan</PPButton>
        <PPButton class="ml-5" v-if="editMode" color="danger" @click="deleteOrder">
            Verwijder
        </PPButton>
    </form>
</template>

<script setup lang="ts">
    import PPHeader from '@/components/PPHeader.vue';
    import PPInput from '@/components/PPInput.vue';
    import PPButton from '@/components/PPButton.vue';
    import PPSelect from '@/components/PPSelect.vue';
    import PPAlert from '@/components/PPAlert.vue';

    import { inject, onMounted, ref, watch } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useFlashMessage } from '@/stores/flash-message';

    import { FlashMessageType } from '@/types/flash-message';

    // Form and validation
    import { useVuelidate } from '@vuelidate/core';
    import { required, email, requiredIf, minValue, maxValue, integer } from '@vuelidate/validators';

    import countries from '@/utils/countries';

    const axios = inject('axios');

    const route = useRoute();
    const router = useRouter();
    const flashMessage = useFlashMessage();
    const editMode = ref(false);
    const order = ref({});
    const selectedCustomer = ref<Object | null>(null);
    const pickupLocationOptions = ref([]);
    const selectedPickupLocation = ref({});
    const timeslotOptions = ref([]);
    const countryOptions: Option[] = countries.map((c) => ({ name: c.name, value: c.code }));
    const taxCategoryOptions = ref([]);
    const channelOptions = ref([]);
    const stateOptions: Option[] = [
        { name: 'Wachten op betaling', value: 'awaiting_payment' },
        { name: 'Betaald', value: 'paid' },
        { name: 'Geannuleerd', value: 'cancelled' },
        { name: 'Terugbetaald', value: 'refunded' }
    ];

    // Types
    import type { Option } from '@/components/PPSelect.vue';
    import type { ComboboxOptionObject } from '@/components/PPComboSelect.vue';
    import type { Address } from '@/types/api/response/address';
    import PPComboSelect from '@/components/PPComboSelect.vue';
    import PPOrderLine from '@/components/PPOrderLine.vue';

    type OrderProduct = {
        productName: string;
        productCode: string;
        quantity: number;
        category?: string;
        description?: string;
    };

    type OrderForm = {
        id?: string;
        channel?: string;
        state: string;
        customer: {
            id?: string;
            name: string;
            email: string;
        };
        pickupLocation: string;
        pickupTimeslot?: string;
        discountPercent?: number;
        lines: Array<OrderProduct>;
        invoiceAddress: Address;
    };

    const orderForm = ref<OrderForm>({
        id: undefined,
        state: 'awaiting_payment',
        customer: {
            id: undefined,
            name: '',
            email: ''
        },
        channel: undefined,
        invoiceAddress: {
            street: '',
            houseNumber: '',
            zipCode: '',
            city: '',
            country: 'NL'
        },
        pickupLocation: '',
        pickupTimeslot: undefined,
        discountPercent: 0,
        lines: []
    });

    onMounted(async () => {
        if (route.params.id) {
            await getOrder();
            editMode.value = true;
        }

        await getPickupLocations();
        await getTaxCategories();
        await getChannels();
    });

    const formatCustomers = (data: any): Array<ComboboxOptionObject> => {
        return data.map((c) => ({ name: `${c.firstName} ${c.lastName}`, value: c.id, ...c }));
    };

    const updateCustomer = (event) => {
        orderForm.value.channel = undefined;

        if (event === null) {
            orderForm.value.customer.id = undefined;
            selectedCustomer.value = null;
            return;
        }

        orderForm.value.customer.id = event?.id;
    };

    const updatePickupLocation = (event) => {
        orderForm.value.pickupLocation = event.id;
    };

    const updateOrderLine = (index, data) => {
        orderForm.value.lines[index] = data;
    };

    const getOrder = async () => {
        try {
            const response = await axios.get(`/admin/order/${route.params.id}`);

            order.value = response.data;
            orderForm.value = Object.assign({}, orderForm.value, order.value);
            orderForm.value.pickupLocation = orderForm.value.pickupLocation.id;
            orderForm.value.pickupTimeslot = orderForm.value.pickupTimeslot?.id ?? undefined;
            orderForm.value.channel = orderForm.value.channel?.id ?? undefined;
            selectedCustomer.value = orderForm.value.customer.id;
        } catch (e) {
            console.error('Something went wrong!', e);
        }
    };

    const deleteOrder = async () => {
        try {
            const response = await axios.delete(`/admin/order/${route.params.id}`);

            if (response.status === 204) {
                flashMessage.add({
                    type: FlashMessageType.Success,
                    message: 'Successfully deleted order!'
                });
            }

            await router.push({ name: 'orders' });
        } catch (e) {
            error.value = e.data.detail ?? 'Something went wrong!';
            console.error('Something went wrong!', e);
        }
    };

    const getPickupLocations = async () => {
        try {
            const response = await axios.get('/admin/shipping/pickup-locations');
            pickupLocationOptions.value = response.data.data.map((c) => ({
                name: c.name,
                value: c.id,
                ...c
            }));
        } catch (e) {
            console.error('Something went wrong!', e);
        }
    };

    const getPickupLocationDetails = async (id) => {
        try {
            const response = await axios.get('/admin/shipping/pickup-location/' + id);
            selectedPickupLocation.value = response.data;
            timeslotOptions.value = selectedPickupLocation.value.timeslots.map((t) => ({
                name: `${new Date(t.date).toLocaleDateString()} - ${t.startTime} - ${t.endTime}`,
                value: t.id
            }));
        } catch (e) {
            console.error('Something went wrong!', e);
        }
    };

    const getChannels = async () => {
        try {
            const response = await axios.get('/admin/channels');
            channelOptions.value = response.data.data.map((c) => ({
                name: c.name,
                value: c.id,
                ...c
            }));
        } catch (e) {
            console.error('Something went wrong!', e);
        }
    };

    const getTaxCategories = async () => {
        try {
            const response = await axios.get('/admin/tax/categories');
            taxCategoryOptions.value = response.data.data.map((c) => ({
                name: c.name,
                value: c.id,
                ...c
            }));
        } catch (e) {
            console.error('Something went wrong!', e);
        }
    };

    // Check if the pickup location changes and get the timeslots
    watch(
        () => orderForm.value.pickupLocation,
        async (_, value) => {
            if (value) {
                orderForm.value.pickupTimeslot = undefined;
            }

            await getPickupLocationDetails(orderForm.value.pickupLocation);
        }
    );

    // Check if the customer changes and prefill the invoice address with their address
    watch(
        () => selectedCustomer.value,
        () => {
            if (selectedCustomer.value?.address) {
                prefillInvoiceAddress();
            }
        }
    );

    const prefillInvoiceAddress = () => {
        orderForm.value.invoiceAddress = Object.assign({}, selectedCustomer.value.address);
        orderForm.value.customer.id = selectedCustomer.value.id;
        orderForm.value.customer.name = `${selectedCustomer.value.firstName} ${selectedCustomer.value.lastName}`;
        orderForm.value.customer.email = selectedCustomer.value.email;

        if (orderForm.value.invoiceAddress.country === '') {
            orderForm.value.invoiceAddress.country = 'NL';
        }
    };

    // Product lines
    const addProduct = () => {
        orderForm.value.lines.push({});
    };

    const removeProduct = (index) => {
        orderForm.value.lines.splice(index, 1);
    };

    const rules = {
        orderForm: {
            state: {},
            customer: {
                id: {},
                name: { required },
                email: { required, email }
            },
            channel: { required: requiredIf(() => !orderForm.value.customer.id) },
            invoiceAddress: {
                street: { required },
                houseNumber: { required },
                zipCode: { required },
                city: { required },
                country: { required }
            },
            pickupLocation: { required },
            pickupTimeslot: {
                required: requiredIf(
                    () =>
                        selectedPickupLocation.value.supportsTimeslots &&
                        selectedPickupLocation.value.timeslots?.length
                )
            },
            discountPercent: { required, integer, minValue: minValue(0), maxValue: maxValue(100) }
        }
    };

    const v$ = useVuelidate(rules, { orderForm });
    const error = ref('');

    // Form submission
    const submit = async () => {
        const result = await v$.value.$validate();

        if (!result) {
            return;
        }

        error.value = '';

        const url = editMode.value ? `/admin/order/${route.params.id}` : '/admin/order';
        const method = editMode.value ? 'PATCH' : 'POST';
        const data: any = orderForm.value;

        try {
            const response = await axios({ method, url, data });

            if (response.status === 200 || response.status === 201) {
                const action = editMode.value ? 'updated' : 'created';

                flashMessage.add({
                    type: FlashMessageType.Success,
                    message: `Successfully ${action} order!`
                });
            }

            await router.push({ name: 'orders' });
        } catch (e) {
            error.value = e.data.detail;
            console.error('Something went wrong while updating or creating order!', e);
        }
    };
</script>
