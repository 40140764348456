<template>
    <PPHeader title="Bestellingen" href="new-order" />

    <div class="mt-3">
        <PPSelect
            name="source"
            :options="sourceOptions"
            v-model="filters.source"
            label="Oorsprong"
            class="w-64 mb-3"
        />

        <PPDataTable
            :headers="headers"
            resourceUrl="/admin/orders"
            :row-clicked="(row) => $router.push({ name: 'single-order', params: { id: row.id } })"
            :filter="true"
            :filters="filters"
        >
            <template #cell(createdAt)="{ value }">
                {{
                    new Date(value).toLocaleDateString('nl-NL', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                    })
                }}
            </template>

            <template #cell(customer)="{ value }"> {{ value.name }} ({{ value.email }})</template>

            <template #cell(state)="{ value }">
                <PPBadge :color="value">{{ badgeText(value) }}</PPBadge>
            </template>

            <template #cell(totalGross)="{ value }">€ {{ formatPrice(value) }}</template>

            <template #cell(packing-slip)="{ row }">
                <div class="flex items-center">
                    <PPButton
                        class="mt-0 mr-2"
                        @click.stop="downloadPackingSlip(row['id'], row['number'])"
                    >
                        <ArrowDownTrayIcon class="h-5 w-5" />
                    </PPButton>

                    <svg width="15" height="15">
                        <circle
                            cx="50%"
                            cy="50%"
                            r="50%"
                            :class="row['packingSlipDownloadedAt'] ? 'fill-primary' : 'fill-danger'"
                        />
                    </svg>
                </div>
            </template>
        </PPDataTable>
    </div>
</template>

<script setup lang="ts">
    import type { Axios, AxiosResponse } from 'axios';

    import { inject, ref } from 'vue';
    import { saveAs } from 'file-saver';

    import { ArrowDownTrayIcon } from '@heroicons/vue/24/outline';

    import PPDataTable from '@/components/PPDataTable.vue';
    import PPHeader from '@/components/PPHeader.vue';
    import PPBadge from '@/components/PPBadge.vue';
    import PPButton from '@/components/PPButton.vue';
    import PPSelect from '@/components/PPSelect.vue';

    import { formatPrice, translateOrderStatus } from '@/utils/utils';

    import type { Option } from '@/components/PPSelect.vue';

    const axios: Axios = inject<Axios>('axios')!;

    const headers = [
        { key: 'number', title: 'Ordernummer' },
        { key: 'customer', title: 'Klant' },
        { key: 'state', title: 'Status' },
        { key: 'totalGross', title: 'Totaal' },
        { key: 'createdAt', title: 'Datum' },
        { key: 'packing-slip', title: 'Pakbon' }
    ];

    const source = ref(undefined);
    const sourceOptions: Option[] = [
        { name: 'Alle', value: '' },
        { name: 'Web', value: 'web' },
        { name: 'Shop', value: 'pos' }
    ];

    const filters = ref({
        source: source.value,
    });

    const badgeText = (value: string) => {
        return translateOrderStatus(value);
    };

    const downloadPackingSlip = async (id, orderNumber) => {
        try {
            const response: AxiosResponse<any> = await axios.get(
                `/admin/order/${id}/packing-slip`,
                {
                    responseType: 'blob'
                }
            );

            // Get filename from content-disposition header.
            const contentDisposition = response.headers['content-disposition'] ?? '';
            const filename =
                contentDisposition.match(/filename="(.+)"/)[1] ?? `Pakbon order ${orderNumber}.pdf`;

            await saveAs(response.data, filename);
        } catch (e) {
            console.error('Something went wrong!', e);
        }
    };
</script>
