<template>
    <span class="inline-flex rounded-sm px-2 py-1 text-xs font-medium" :class="badgeColor">
        <slot />
    </span>
</template>
<script setup lang="ts">
    import { computed } from 'vue';

    const props = defineProps<{
        color: string;
    }>();

    const badgeColor = computed(() => {
        if (props.color === 'warning' || props.color === 'refunded') {
            return 'bg-yellow-100 text-yellow-800';
        } else if (props.color === 'danger' || props.color === 'cancelled') {
            return 'bg-red-100 text-red-700';
        } else if (props.color === 'success' || props.color === 'paid') {
            return 'bg-green-100 text-green-700';
        } else if (props.color === 'info' || props.color === 'awaiting_payment') {
            return 'bg-blue-100 text-blue-700';
        }

        return '';
    });
</script>
