<template>
    <div class="flex items-end gap-x-3 py-3">
        <dt class="text-sm font-medium leading-6 text-gray-900 flex-1">
            <PPComboSelect
                v-model="product"
                name="product_name"
                label="Productnaam"
                @update:modelValue="updateProductName"
                url="/admin/product/variants?search[name]=%query%"
                :allow-custom="true"
                :allow-none="true"
                :errors="v$.productName.$errors.length"
            />
        </dt>
        <dd class="flex-1">
            <PPInput
                name="product_code"
                v-model="orderLine.productCode"
                label="Productcode"
                :errors="v$.productCode.$errors.length"
            />
        </dd>
        <dd class="hidden">
            <PPInput
                name="pick_code"
                v-model="orderLine.productPickCode"
                label="Orderpick code"
                :errors="v$.productPickCode.$errors.length"
            />
        </dd>
        <dd class="w-24">
            <PPSelect
                name="category"
                :options="categoryOptions"
                v-model="orderLine.category"
                label="Categorie"
                :allowNone="true"
                :errors="v$.category.$errors.length"
            />
        </dd>
        <dd class="flex-1">
            <PPInput
                name="description"
                v-model="orderLine.description"
                label="Beschrijving"
                :errors="v$.description.$errors.length"
            />
        </dd>
        <dd class="w-16">
            <PPInput
                name="quantity"
                v-model="orderLine.quantity"
                type="number"
                label="Aantal"
                :errors="v$.quantity.$errors.length"
            />
        </dd>
        <dd class="w-32">
            <PPInput
                name="total_excl"
                v-model="orderLine.totalGross"
                type="number"
                label="Totaal (incl. BTW)"
                placeholder="Totaal"
                :errors="v$.totalGross.$errors.length"
            />
        </dd>
        <dd class="w-16">
            <PPInput
                name="tax_percent"
                v-model="orderLine.taxPercent"
                type="number"
                label="BTW (%)"
                placeholder="BTW percentage"
                :errors="v$.taxPercent.$errors.length"
            />
        </dd>
        <dd class="w-32">
            <PPSelect
                name="tax_category"
                :options="taxCategoryOptions"
                label="BTW categorie"
                v-model="orderLine.taxCategory"
                :errors="v$.taxCategory.$errors.length"
                :allowNone="true"
            />
        </dd>
        <dd class="grow-0">
            <PPButton color="danger" size="block" @click="emits('removeProduct')">x</PPButton>
        </dd>
    </div>
</template>
<script setup lang="ts">
    import type { Option } from '@/components/PPSelect.vue';

    import PPInput from '@/components/PPInput.vue';
    import PPButton from '@/components/PPButton.vue';
    import PPComboSelect from '@/components/PPComboSelect.vue';
    import PPSelect from '@/components/PPSelect.vue';

    import { onMounted, ref, watch } from 'vue';

    import { useVuelidate } from '@vuelidate/core';
    import { minValue, required, requiredIf } from '@vuelidate/validators';

    const emits = defineEmits(['update:orderLine', 'removeProduct']);
    const props = defineProps(['orderLine', 'taxCategoryOptions']);

    const product = ref({});
    const categoryOptions: Option[] = [
        { name: 'Cat 1', value: 'cat1' },
        { name: 'Cat 2', value: 'cat2' }
    ];

    // Set the product to the orderLine
    // In order for the pre-fill on the product name to work, we need to set the "name" property,
    // this way the modelValue can pick up on it and display it in the combobox
    onMounted(() => {
        product.value = props.orderLine;
        product.value.name = props.orderLine.productName;
    });

    watch(
        () => props.orderLine,
        () => {
            product.value.name = props.orderLine.productName;
        }
    );

    // When the quantity changes, recalculate the total price for the product
    watch(
        () => props.orderLine.quantity,
        (newValue, oldValue) => {
            if (!props.orderLine.totalGross || !oldValue) {
                return;
            }

            props.orderLine.totalGross = (props.orderLine.totalGross / oldValue) * newValue;
        }
    );

    // When the product name changes we receive a new product, set the orderLine to the data from that product
    const updateProductName = (event) => {
        if (!event) {
            return;
        }

        product.value = event;

        props.orderLine.productCode = event.code;
        props.orderLine.productName = event.name;
        props.orderLine.taxCategory = event.taxCategory;
        props.orderLine.category = event.category;
        props.orderLine.totalGross = event.price;
        props.orderLine.productPickCode = event.pickCode;
        props.orderLine.weight = event.weight ?? undefined;
        props.orderLine.gunpowderWeight = event.gunpowderWeight ?? undefined;
        props.orderLine.quantity = 1;

        emits('update:orderLine', props.orderLine);
    };

    const rules = {
        productName: { required },
        productCode: { required },
        productPickCode: {},
        category: {},
        description: {},
        totalGross: { required, min: minValue(0) },
        taxPercent: { required: requiredIf(() => !props.orderLine?.taxCategory) },
        taxCategory: {},
        quantity: { required, min: minValue(1) }
    };

    const v$ = useVuelidate(rules, props.orderLine);
</script>
