<template>
    <div class="mx-auto max-w-7xl p-4">
        <PPHeader :title="`Bestelling #${order.number ?? ''}`" />

        <div class="mt-1 flex justify-between items-center space-x-5 text-sm text-gray-500">
            <div>
                <PPBadge :color="order.state">{{ translateOrderStatus(order.state) }}</PPBadge>
            </div>

            <div class="flex gap-x-3">
                <PPButton @click="sendOrderConfirmation">
                    <EnvelopeIcon class="h-5 w-5 mr-2" />
                    Verstuur bevestiging
                </PPButton>

                <PPButton @click="downloadInvoice">
                    <ArrowDownTrayIcon class="h-5 w-5 mr-2" />
                    Factuur
                </PPButton>

                <PPButton @click="downloadPackingSlip">
                    <ArrowDownTrayIcon class="h-5 w-5 mr-2" />
                    Pakbon
                </PPButton>

                <PPButton
                    @click="$router.push({ name: 'edit-order', params: { id: $route.params.id } })"
                >
                    Bewerken
                </PPButton>
            </div>
        </div>

        <div class="mt-8 mx-auto grid items-start gap-x-8 grid-cols-4">
            <!-- Invoice summary -->
            <div class="grid gap-y-5">
                <div class="rounded-sm border border-gray-200">
                    <div class="flex flex-col flex-wrap">
                        <div class="uppercase font-bold border-b p-4 bg-gray-50">Klant</div>
                        <div class="text-sm p-4">
                            <p class="font-semibold">{{ order.customer.name }}</p>
                            <a class="text-primary" :href="`mailto:${order.customer.email}`">
                                {{ order.customer.email }}
                            </a>
                        </div>
                    </div>
                </div>

                <div class="rounded-sm border border-gray-200">
                    <div class="flex flex-col flex-wrap divide-y divide-gray-200">
                        <div class="uppercase font-bold p-4 bg-gray-50">Factuuradres</div>
                        <address class="text-sm p-4 not-italic">
                            <p class="font-semibold">{{ order.customer.name }}</p>
                            <p>
                                {{ order.invoiceAddress.street }}
                                {{ order.invoiceAddress.houseNumber }}
                            </p>
                            <p>
                                {{ order.invoiceAddress.zipCode }} {{ order.invoiceAddress.city }},
                                {{ order.invoiceAddress.country }}
                            </p>
                        </address>

                        <div class="uppercase font-bold p-4 bg-gray-50">Afhaallocatie</div>
                        <address class="text-sm p-4 not-italic">
                            <p class="font-semibold">
                                {{ pickupLocation.name ?? order.pickupLocation.name }}
                            </p>
                            <template v-if="pickupLocation.id">
                                <p>
                                    {{ pickupLocation.address.street }}
                                    {{ pickupLocation.address.houseNumber }}
                                </p>
                                <p>
                                    {{ pickupLocation.address.zipCode }}
                                    {{ pickupLocation.address.city }},
                                    {{ pickupLocation.address.country }}
                                </p>
                                <p>{{ pickupLocation.website }}</p>
                            </template>
                            <template v-if="order.pickupTimeslot">
                                <p class="font-semibold mt-2">Tijdstip</p>
                                <p>
                                    {{ formatDate(order.pickupTimeslot.date) }} -
                                    {{ order.pickupTimeslot.startTime }} -
                                    {{ order.pickupTimeslot.endTime }}
                                </p>
                            </template>
                        </address>
                    </div>
                </div>
            </div>

            <!-- Order -->
            <div class="sm:mx-0 sm:rounded-lg col-span-3 row-span-3 row-end-3">
                <table class="border w-full whitespace-nowrap text-left text-sm leading-6">
                    <colgroup>
                        <col class="w-full" />
                        <col />
                        <col />
                        <col />
                    </colgroup>
                    <thead class="border-b border-gray-200 text-gray-900">
                        <tr>
                            <th scope="col" class="px-4 py-3 font-semibold">Producten</th>
                            <th
                                scope="col"
                                class="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell"
                            >
                                Aantal
                            </th>
                            <th
                                scope="col"
                                class="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell"
                            >
                                Stukprijs
                            </th>
                            <th scope="col" class="py-3 pl-8 pr-4 text-right font-semibold">
                                Totaal
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="item in order?.lines"
                            :key="item.productCode"
                            class="border-b border-gray-100"
                        >
                            <td class="max-w-0 px-4 py-5 align-top">
                                <div class="truncate font-medium text-gray-900">
                                    {{ item.productName }}
                                </div>
                                <div class="truncate text-gray-500">{{ item.productCode }}</div>
                            </td>
                            <td
                                class="hidden py-5 pl-8 pr-0 text-right align-middle tabular-nums text-gray-700 sm:table-cell"
                            >
                                {{ item.quantity }}
                            </td>
                            <td
                                class="hidden py-5 pl-8 pr-0 text-right align-middle tabular-nums text-gray-700 sm:table-cell"
                            >
                                {{ formatPrice(item.totalGross / item.quantity) }}
                            </td>
                            <td
                                class="py-5 pl-8 pr-4 text-right align-middle tabular-nums text-gray-700"
                            >
                                {{ formatPrice(item.totalGross) }}
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th
                                scope="row"
                                class="px-0 pb-0 pt-6 font-normal text-gray-700 sm:hidden"
                            ></th>
                            <th
                                scope="row"
                                colspan="3"
                                class="hidden px-0 pb-0 pt-6 text-right font-normal text-gray-700 sm:table-cell"
                            >
                                Subtotaal
                            </th>
                            <td class="pb-0 pl-8 pr-4 pt-6 text-right tabular-nums text-gray-900">
                                {{ formatPrice(order.subTotalGross) }}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" class="pt-4 font-normal text-gray-700 sm:hidden"></th>
                            <th
                                scope="row"
                                colspan="3"
                                class="hidden px-0 pb-0 pt-4 text-right font-normal text-gray-700 sm:table-cell"
                            >
                                Verzendkosten
                            </th>
                            <td class="pb-0 pl-8 pr-4 pt-4 text-right tabular-nums text-gray-900">
                                {{ formatPrice(order.shippingGross) }}
                            </td>
                        </tr>
                        <tr v-if='order.discountPercent > 0'>
                            <th scope="row" class="pt-4 font-normal text-gray-700 sm:hidden"></th>
                            <th
                                scope="row"
                                colspan="3"
                                class="hidden px-0 pb-0 pt-4 text-right font-normal text-gray-700 sm:table-cell"
                            >
                                Korting ({{ order.discountPercent }}%)
                            </th>
                            <td class="pb-0 pl-8 pr-4 pt-4 text-right tabular-nums text-gray-900">
                                {{ formatPrice(order.discount) }}
                            </td>
                        </tr>
                        <tr v-for="(tax, percentage) in order.totalTax" :key="tax">
                            <th scope="row" class="pt-4 font-normal text-gray-700 sm:hidden"></th>
                            <th
                                scope="row"
                                colspan="3"
                                class="hidden pt-4 text-right font-normal text-gray-700 sm:table-cell"
                            >
                                BTW ({{ percentage }}%)
                            </th>
                            <td class="pb-0 pl-8 pr-4 pt-4 text-right tabular-nums text-gray-900">
                                {{ formatPrice(tax) }}
                            </td>
                        </tr>
                        <tr>
                            <th scope="row" class="pt-4 font-semibold text-gray-900 sm:hidden">
                                Total
                            </th>
                            <th
                                scope="row"
                                colspan="3"
                                class="hidden pt-4 text-right font-semibold text-gray-900 sm:table-cell"
                            >
                                Totaal
                            </th>
                            <td
                                class="pb-0 pl-8 pr-4 pt-4 text-right font-semibold tabular-nums text-gray-900"
                            >
                                {{ formatPrice(order.totalGross) }}
                            </td>
                        </tr>
                    </tfoot>
                </table>

                <div class="mt-3">
                    <!--                    <PPTextArea name="notes" v-model="order?.notes" label="Opmerkingen" />-->
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { inject, onMounted, ref } from 'vue';
    import { useRoute } from 'vue-router';
    import { saveAs } from 'file-saver';
    import { useToast } from "vue-toastification";

    import { ArrowDownTrayIcon, EnvelopeIcon } from '@heroicons/vue/20/solid';

    import PPHeader from '@/components/PPHeader.vue';
    import PPBadge from '@/components/PPBadge.vue';
    import PPButton from '@/components/PPButton.vue';

    import { translateOrderStatus, formatPrice } from '@/utils/utils';

    const route = useRoute();
    const axios = inject('axios');

    const pickupLocation = ref({});

    const order = ref({
        customer: {},
        invoiceAddress: {},
        lines: [],
        pickupLocation: {},
        pickupTimeslot: {},
        channel: {}
    });

    const toast = useToast();

    onMounted(async () => {
        await getOrder();
        await getPickupLocationDetails();
    });

    const getOrder = async () => {
        try {
            const response = await axios.get(`/admin/order/${route.params.id}`);

            order.value = response.data;
        } catch (e) {
            console.error('Something went wrong!', e);
        }
    };

    const getPickupLocationDetails = async () => {
        try {
            const response = await axios.get(
                `/admin/shipping/pickup-location/${order.value.pickupLocation.id}`
            );

            if (response.status === 200) {
                pickupLocation.value = response.data;
            }
        } catch (e) {
            console.error('Something went wrong!', e);
        }
    };

    const downloadPackingSlip = async () => {
        try {
            const response = await axios.get(`/admin/order/${route.params.id}/packing-slip`, {
                responseType: 'blob'
            });

            // Get filename from content-disposition header.
            const contentDisposition = response.headers['content-disposition'] ?? '';
            const filename =
                contentDisposition.match(/filename="?(.+)"?/) ? contentDisposition.match(/filename="?(.+)"?/)[1] :
                `Pakbon order ${order.value.number}.pdf`;

            await saveAs(response.data, filename);
        } catch (e) {
            console.error('Something went wrong!', e);
        }
    };

    const downloadInvoice = async () => {
        try {
            const response = await axios.get(`/admin/order/${route.params.id}/invoice`, {
                responseType: 'blob'
            });

            // Get filename from content-disposition header.
            const contentDisposition = response.headers['content-disposition'] ?? '';
            console.log(contentDisposition);
            const filename =
                contentDisposition.match(/filename="?(.+)"?/) ? contentDisposition.match(/filename="?(.+)"?/)[1] :
                `Factuur order ${order.value.number}.pdf`;

            await saveAs(response.data, filename);
        } catch (e) {
            console.error('Something went wrong!', e);
        }
    };

    const sendOrderConfirmation = () => {
        axios.post(`/admin/order/${route.params.id}/send-confirmation`, '').then((response: any) => {
            if (response.status === 204) {
                toast.success('Orderbevestiging is verstuurd!');
            }
        });
    };

    const formatDate = (date) => {
        if (!date || date === '') {
            return '';
        }

        return new Date(date).toLocaleDateString('nl-NL', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };
</script>
