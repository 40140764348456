<template>
    <Combobox
        as="div"
        v-model="selectedOption"
        :nullable="allowNone"
        @update:modelValue="$emit('update:modelValue', selectedOption)"
    >
        <ComboboxLabel class="block text-sm font-medium text-gray-700 mb-1" v-if="label"
            >{{ label }}
        </ComboboxLabel>
        <div class="relative">
            <ComboboxInput
                class="w-full rounded-sm border bg-white py-1.5 pl-3 pr-10 text-gray-900 text-sm shadow-sm focus:ring-primary focus:border-primary sm:leading-6 transition duration-300"
                :class="[errors ? 'border-danger' : 'border-gray-300']"
                :display-value="(option) => option?.name"
                @change="query = $event.target.value"
            />
            <ComboboxButton
                class="absolute inset-y-0 right-0 flex items-center rounded-r-sm px-2 focus:outline-none"
            >
                <ChevronDownIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
            </ComboboxButton>

            <ComboboxOptions
                class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-sm bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
                <ComboboxOption
                    v-for="option in currentOptions"
                    :key="option.name"
                    :value="option"
                    as="template"
                    v-slot="{ active, selected }"
                >
                    <li
                        :class="[
                            'text-sm relative cursor-default select-none py-2 pl-3 pr-9',
                            active ? 'bg-primary text-white' : 'text-gray-900'
                        ]"
                    >
                        <span :class="['block truncate', selected && 'font-semibold']">
                            {{ option.name }}
                        </span>
                    </li>
                </ComboboxOption>
            </ComboboxOptions>
        </div>
    </Combobox>
</template>

<script setup lang="ts">
    import { inject, ref, watch } from 'vue';
    import { ChevronDownIcon } from '@heroicons/vue/20/solid';
    import {
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions
    } from '@headlessui/vue';
    import { watchDebounced } from '@vueuse/core';

    export type ComboboxOptionObject = {
        name: string;
        value: string;
        [x: string | number | symbol]: unknown;
    };

    const props = defineProps<{
        label: string;
        modelValue?: any;
        name: string;
        url?: string;
        options?: Array<ComboboxOptionObject>;
        allowNone?: boolean;
        allowCustom?: boolean;
        errors?: number;
        formatResponseData?: (any) => Array<ComboboxOptionObject>;
    }>();

    defineEmits(['update:modelValue']);

    const axios = inject('axios');

    const query = ref<string>('');
    const currentOptions = ref<Array<ComboboxOptionObject>>([]);
    const selectedOption = ref<ComboboxOptionObject | null>(null);

    watchDebounced(
        query,
        async () => {
            let options: Array<ComboboxOptionObject> = [];

            if (query.value === '') {
                currentOptions.value = options;

                return;
            }

            if (!props.url) {
                options = (props.options ?? []).filter((option) => {
                    return option.name.toLowerCase().includes(query.value.toLowerCase());
                });

                // Prepend our custom option.
                if (props.allowCustom === true) {
                    options.unshift({
                        name: query.value,
                        value: query.value
                    });
                }

                currentOptions.value = options;

                return;
            }

            // Replace %query% in URL.
            const url = props.url.replaceAll(/%query%/gi, encodeURIComponent(query.value));

            try {
                const response = await axios.get(url);

                if (response.status === 200) {
                    options = response.data.data;

                    if (props.formatResponseData) {
                        options = props.formatResponseData(options);
                    }
                }
            } catch (e) {
                // TODO: Catch with sentry.

                console.error('Something went wrong!', e);
            }

            // Prepend our custom option.
            if (props.allowCustom === true) {
                options.unshift({
                    name: query.value,
                    value: query.value
                });
            }

            currentOptions.value = options;
        },
        {
            debounce: 250
        }
    );

    // Check if the modelValue is changed, set the selectedOption to the new value
    watch(
        () => props.modelValue,
        () => {
            selectedOption.value = props.modelValue;
        }
    );

    // Check if the options have changed, set the currentOptions to the new options
    watch(
        () => props.options,
        (options) => {
            currentOptions.value = options;
        }
    );
</script>
